// Colors
// $primary-color: #292C2F;
$primary-color: #3e3b37;
// $secondary-color:#73a540;
$secondary-color:#b8bc2f;
// $accent-color: #6EB636;
$accent-color: #C1b900;
$secondary-light-color: #d5d584;
$accent-light-color: #f4f4e0;
$background-grey: #F5F5F5;
$light-grey: #bcbcba;
$dark-grey: #83807e;
$error-color: rgb(179, 46, 46);
$warning-color: rgb(231, 178, 33);
$info-color: rgb(54, 126, 221);

// Sizes
$btn-font-size: 18px;
