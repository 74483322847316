/* You can add global styles to this file, and also import other style files */
// Google fonts
@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700,900,100italic,300italic,400italic,700italic,900italic);
// Material theme
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

// Calendar
@import 'angular-calendar/css/angular-calendar.css';

// Variables
@import './assets/styles/variables/variables.scss';
@import './assets/styles/mixings/mixings.scss';

// Carousel
@import '@glidejs/glide/src/assets/sass/glide.core';

// Font
@font-face {
  font-family: 'raustila';
  src: url('./assets/fonts/raustila-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'aeonik-bold';
  src: url('./assets/fonts/aeonik/Aeonik-Bold.otf');
}

@font-face {
  font-family: 'aeonik-bold-italic';
  src: url('./assets/fonts/aeonik/Aeonik-BoldItalic.otf');
}

@font-face {
  font-family: 'aeonik-light';
  src: url('./assets/fonts/aeonik/Aeonik-Regular.otf');
}

@font-face {
  font-family: 'aeonik-italic';
  src: url('./assets/fonts/aeonik/Aeonik-BlackItalic.otf');
}

@font-face {
  font-family: 'aeonik-regular';
  src: url('./assets/fonts/aeonik/Aeonik-Light.otf');
}

@font-face {
  font-family: 'aeonik-medium';
  src: url('./assets/fonts/aeonik/Aeonik-Medium.otf');
}

@font-face {
  font-family: 'aeonik-regular-italic';
  src: url('./assets/fonts/aeonik/Aeonik-RegularItalic.otf');
}

body,
html,
h3 {
  // font-family: Montserrat, sans-serif;
  font-family: 'aeonik-regular';
  text-rendering: optimizeLegibility;
  color: #5a5a5a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.no-scroll {
  overflow: hidden !important;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

/** Override styles **/
.sticky-top {
  top: 110px;
  position: -webkit-sticky;
  position: sticky;

  @include media-query-mobile {
    top: 80px;
  }
}

// Accordion
.mat-expansion-indicator::after,
.mat-expansion-panel-header-title {
  color: $secondary-color;
}

// Progress
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.mat-progress-bar-fill::after {
  background-color: $secondary-color;
}

// Form
.mat-mdc-form-field {
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: $secondary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: $secondary-color;
}

.form-control:focus {
  box-shadow: none;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
}

// Button
.mat-mdc-raised-button[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

// Radio
.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  border-color: $secondary-color !important;
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: $secondary-color !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
mat-radio-group {
  width: 100%;

  .mat-mdc-radio-button {
    width: 100%;

    @include media-query-desktop {
      width: 33%;
    }
  }
}

// Checkbox
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background-color: $secondary-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout {
  white-space: normal !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-inner-container {
  margin-top: 4px !important;
}

// Snackbar
.custom-snackbar-alert {
  .mat-mdc-snack-bar-action {
    color: $secondary-color;
  }
}

// Overlay
.cdk-overlay-container {
  z-index: $zindex-modal-backdrop;
}

// Tabs

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-group[mat-stretch-tabs] > .mat-mdc-tab-header .mat-tab-label {
  flex-basis: unset;
}

// SELECT
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $secondary-color;
}

.mdc-text-field--focused {
  border-color: $secondary-color;
}

.mdc-notched-outline__notch {
  display: none;
}

.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
  color: inherit !important;
  -webkit-text-fill-color: inherit !important;
}

// States
.state {
  color: white;
  font-size: 0.8rem;
  font-family: 'aeonik-bold';
  padding: 0 8px;
  border-radius: 8px;
  vertical-align: middle;

  &-process {
    background-color: rgb(49, 49, 235);
  }

  &-change {
    background-color: rgb(62, 176, 252);
  }

  &-payment-pending {
    background-color: #6c757d;
  }

  &-update {
    background-color: $error-color;
  }

  &-cancel {
    background-color: $warning-color;
  }

  &-confirm {
    background-color: $secondary-color;
  }
}

.cdk-focused {
  outline-color: transparent !important;
}

.non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-padding {
  .main-body {
    padding: 0 !important;
  }
}

.status {
  margin: 0 10px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;

  &--process {
    background-color: rgb(49, 49, 235);
  }

  &--change {
    background-color: rgb(62, 176, 252);
  }

  &--payment-pending {
    background-color: #6c757d;
  }

  &--update {
    background-color: $error-color;
  }

  &--cancel {
    background-color: $warning-color;
  }

  &--confirm {
    background-color: $secondary-color;
  }
}
