@import '../../../../node_modules/bootstrap/scss/functions.scss';
@import '../../../../node_modules/bootstrap/scss/variables.scss';
@import '../../../../node_modules/bootstrap/scss/mixins.scss';

@mixin display-flex {
  display: flex;
  align-items: center;
}

@mixin opaque-primary-bg {
  background-color: rgba(0, 0, 0, 0.7);
}

@mixin media-query-desktop {
  @media (min-width: 1240px) {
    @content;
  }
}
@mixin media-query-mobile {
  @media (max-width: 1240px) {
    @content;
  }
}
